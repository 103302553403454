<template>
  <div style="padding: 20px">
    <h2>（一）门诊绿通权益服务</h2>
    <p>
      <u><strong>【服务特点】</strong></u>
    </p>
    <p>快速挂号、轻松就医、服务保障</p>
    <p>
      <u><strong>【什么是门诊绿通服务】</strong></u>
    </p>
    <p>
      门诊绿通是一种特殊的医疗服务模式，主要面向高层次、高水平的医学专家。它可以为您解决专家门诊资源有限引起的挂号难、等候久的就医难题。
    </p>
    <p>
      <u><strong>【权益说明】</strong></u>
    </p>
    <p>
      (1) &nbsp; &nbsp;
      当用户预约看诊服务时，根据用户疾病症状、既往检查结果及就诊经过进行分诊，5~7个工作日内为用户安排最合适的全国2400多家公立二甲及三甲医院副主任或主任医师级别医生门诊。可指定医院指定科室，不可指定医生。
    </p>
    <p>
      (2) &nbsp; &nbsp;
      医院产生的挂号费、诊疗费、药费、检查费、护理费等直接支付给医院的费用，不属于专家预约服务承担范围。
    </p>
    <p>
      (3) &nbsp; &nbsp;
      本服务不包含紧急就诊的预约，如病情紧急请直接拨打120或前往医院急诊就诊，不含分娩、产科、生殖科、急诊科、口腔美容保健等预约。
    </p>
    <p>
      (4) &nbsp; &nbsp;
      限车主本人120种重大疾病使用，30天激活等待期，取得二级及以上公立医院诊断后可以发起服务申请。
    </p>
    <p>(5) &nbsp; &nbsp; 车主权益券不可叠加使用，不可拆分，不兑现。</p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
